import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HelpNavLayout from '../components/help-nav-layout'
import { TemplateProps } from '../utils/types'
import { renderBody } from '../utils/sections'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import Cta from '../components/cta'

export const query = graphql`
  query HelpPage($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      ...PageData
    }
  }
`

const titleSx: ThemeUIStyleObject = {
  display: ['none', 'none', 'initial'],
  fontFamily: 'Lubalin Graph Std',
  fontWeight: '600',
  fontSize: '2.5rem',
  marginBottom: '2.5rem',
}

const HelpPage: React.FC<TemplateProps> = ({
  data: { page },
  pageContext: {
    navigation,
    footerNavigation,
    helpNavigation,
    ourStoryNavigation,
    helpPageHero,
  },
  location,
}) => {
  const pageHero = page?.pageHero || helpPageHero

  return (
    <Layout
      page={page}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      {pageHero && <Cta {...pageHero} />}
      <HelpNavLayout helpNavigation={helpNavigation} location={location}>
        <Text sx={titleSx}>{page.title}</Text>
        {page.body && renderBody(page)}
      </HelpNavLayout>
    </Layout>
  )
}

export default HelpPage
