import React, { ReactElement, useState } from 'react'
import { Flex, Link, ThemeUIStyleObject } from 'theme-ui'
import { NavItemProps } from '../utils/types'
import { WindowLocation } from '@reach/router'

type HelpNavLayoutProps = {
  helpNavigation: NavItemProps[]
  location: WindowLocation
  children: ReactElement | ReactElement[]
}

const wrapperSx: ThemeUIStyleObject = {
  maxWidth: ['initial', 'initial', '62rem', '78rem'],
  marginX: 'auto',
  justifyContent: 'center',
  marginTop: ['initial', 'initial', '2.25rem'],
}

const navWrapperSx: ThemeUIStyleObject = {
  display: ['none', 'none', 'initial'],
  marginLeft: '2rem',
  marginRight: '0.875rem',
  flex: '0 0 23%',
  flexDirection: 'column',
}

const baseNavSx: ThemeUIStyleObject = {
  textAlign: 'center',
  border: '1px solid #D4D7D7',
  marginTop: '-1px',
  color: 'black',
  textDecoration: 'none',
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  lineHeight: '3.5rem',
  height: '3.5rem',
  ':hover': {
    background: '#9AD339',
    cursor: 'pointer',
  },
}

const contentWrapperSx: ThemeUIStyleObject = {
  flex: ['0 0 100%', '0 0 100%', '0 0 77%'],
  paddingLeft: ['1rem', 'initial', '1rem'],
  flexDirection: 'column',
}
const firstLevelActive: ThemeUIStyleObject = {
  background: '#9AD339',
  fontWeight: 'bold',
  position: 'relative',
  '::after': {
    position: 'absolute',
    right: ['0.75rem', '0.75rem', 0],
    borderStyle: 'solid',
    content: '""',
    borderWidth: '0.225rem 0.225rem 0 0',
    height: '0.45rem',
    width: '0.45rem',
    float: 'right',
    marginTop: '1.25rem',
    transform: [
      'initial',
      'initial',
      'translateY(20%) translateX(-100%) rotate(135deg)',
      'translateY(20%) translateX(-300%) rotate(135deg)',
    ],
  },
}

const firstLevelNavSx: ThemeUIStyleObject = {
  ...baseNavSx,
  position: 'relative',
  '::after': {
    position: 'absolute',
    right: ['0.75rem', '0.75rem', 0],
    borderStyle: 'solid',
    content: '""',
    borderWidth: '0.225rem 0.225rem 0 0',
    height: '0.45rem',
    width: '0.45rem',
    float: 'right',
    marginTop: '1.25rem',
    transform: [
      'initial',
      'initial',
      'translateY(20%) translateX(-100%) rotate(45deg)',
      'translateY(20%) translateX(-300%) rotate(45deg)',
    ],
  },
}
const secondLevelNavSx: ThemeUIStyleObject = {
  ...baseNavSx,
  display: 'none',
  color: '#686767',
  background: '#F1F2F2',
  ':hover': {
    cursor: 'pointer',
    color: 'black',
  },
}
const thirdLevelNavSx: ThemeUIStyleObject = {
  ...baseNavSx,
  display: 'none',
  border: 'none',
  background: '#F1F2F2',
  ':hover': {
    background: '#9AD339',
    fontWeight: 'bold',
  },
}

const generateState = (location: WindowLocation) => {
  const state = { activeLevel1: '', activeLevel2: '', activeLevel3: '' }
  const pathArr = location.pathname.split('/')
  if (pathArr?.length && pathArr.length <= 2) {
    return state
  }
  if (pathArr.length >= 3) {
    state.activeLevel1 = `/${pathArr[1]}/${pathArr[2]}`
  }
  if (pathArr.length >= 4) {
    state.activeLevel2 = `/${pathArr[1]}/${pathArr[2]}/${pathArr[3]}`
  }
  if (pathArr.length >= 5) {
    state.activeLevel3 = `/${pathArr[1]}/${pathArr[2]}/${pathArr[3]}/${pathArr[4]}`
  }
  return state
}

const HelpNavLayout: React.FC<HelpNavLayoutProps> = ({
  helpNavigation,
  children,
  location,
}) => {
  const [menuLevels, setMenuLevels] = useState(generateState(location))
  const menuLevelChange = (navSlug: string, level: number) => {
    const tempCopy = { ...menuLevels }
    if (menuLevels[`activeLevel${level}`] === navSlug) {
      tempCopy[`activeLevel${level}`] = ''
      setMenuLevels(tempCopy)
      return
    }
    tempCopy[`activeLevel${level}`] = navSlug
    setMenuLevels(tempCopy)
  }

  const menuLevel2Sx = (
    parentSlug: string,
    child: NavItemProps
  ): ThemeUIStyleObject => {
    let style: ThemeUIStyleObject = { ...secondLevelNavSx }
    // Show second level nav items when first level menu is active
    if (menuLevels.activeLevel1 === parentSlug) {
      style = { ...style, display: 'initial' }
    }
    // Change to bold if nav item is active
    if (child.slug === menuLevels.activeLevel2) {
      style = { ...style, fontWeight: 'bold' }
    }
    // If there is another layer of sub nav items
    style = child.items ? { ...style, color: 'black' } : { ...style }
    return style
  }

  const menuLevel3Sx = (
    grandParentSlug: string,
    parentSlug: string,
    grandChild: NavItemProps
  ): ThemeUIStyleObject => {
    let style: ThemeUIStyleObject = { ...thirdLevelNavSx }
    // Show third level nav items when second level menu is active
    if (
      menuLevels.activeLevel2 === parentSlug &&
      menuLevels.activeLevel1 === grandParentSlug
    ) {
      style = { ...style, display: 'initial' }
    }
    // Change to bold if nav item is active
    if (grandChild.slug === menuLevels.activeLevel3) {
      style = { ...style, fontWeight: 'bold' }
    }
    return style
  }

  return (
    <Flex sx={wrapperSx}>
      <Flex sx={navWrapperSx}>
        {helpNavigation?.map((navItem: NavItemProps, index: number) => (
          <Flex
            sx={{ flexDirection: 'column' }}
            key={`help-nav-layer1-${index}`}
          >
            <Link
              sx={
                menuLevels.activeLevel1 === navItem.slug
                  ? { ...firstLevelNavSx, ...firstLevelActive }
                  : { ...firstLevelNavSx }
              }
              onClick={() => menuLevelChange(navItem.slug ?? '', 1)}
              href={navItem?.items?.length ? undefined : navItem?.slug}
            >
              {navItem.title}
            </Link>
            {navItem.items?.map((child: NavItemProps, index: number) => (
              <Flex
                sx={{ flexDirection: 'column' }}
                key={`help-nav-layer2-${index}`}
              >
                <Link
                  sx={menuLevel2Sx(navItem.slug ?? '', child)}
                  href={child?.items?.length ? undefined : child?.slug}
                  onClick={() => menuLevelChange(child.slug ?? '', 2)}
                >
                  {child.title}
                </Link>
                {child.items?.map((grandChild: NavItemProps, index: number) => (
                  <Flex
                    sx={{ flexDirection: 'column' }}
                    key={`help-nav-layer3-${index}`}
                  >
                    <Link
                      sx={menuLevel3Sx(
                        navItem.slug ?? '',
                        child.slug ?? '',
                        grandChild
                      )}
                      href={
                        grandChild?.items?.length ? undefined : grandChild?.slug
                      }
                      onClick={() => menuLevelChange(grandChild.slug ?? '', 3)}
                    >
                      {grandChild.title}
                    </Link>
                  </Flex>
                ))}
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
      <Flex sx={contentWrapperSx}>{children}</Flex>
    </Flex>
  )
}

export default HelpNavLayout
